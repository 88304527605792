<template>
  <ui-component-modal
    :modalTitle="$t('Components.ToDos.ModalShareToDo.Modal_Title')"
    :isSaving="isSaving"
    :isSavingSuccess="isSavingSuccess"
    :isSavingError="isSavingError"
    :hideFooterButtons="isSavingError || isSavingSuccess"
    :onClickCancel="onClickCancel"
    :onClickSave="shareToDo"
    :showModal="showModal"
    @closeModal="onClickCancel"
  >
    <template slot="modalTitle"></template>
    <template v-slot:content>
      <div class="form-options columns">
        <div class="search-add column column-search">
          <ui-base-checkbox
            v-model="assignToSelf"
            :label="
              $t('Components.ToDos.ModalShareToDo.Modal_LabelAssignToSelf')
            "
            @input="checkIfAssigned"
            :cssClassLabel="'has-text-weight-bold'"
          />
          <hr />
          <div class="tabs is-boxed">
            <ul>
              <li
                @click="changeTab('Colleagues')"
                :class="{ 'is-active': infoTab === 'Colleagues' }"
              >
                <a>Colleagues</a>
              </li>
            </ul>
          </div>
          <div class="tabs-info">
            <ui-loader v-if="isLoading" />
            <table v-if="!isLoading" class="table is-fullwidth is-striped">
              <tbody>
                <tr
                  v-for="(account, index) in displayAccounts"
                  :key="`op${index}`"
                >
                  <td v-html="account.FullName"></td>
                  <td>
                    <a
                      @click="addAccount(account)"
                      class="button is-small is-success"
                    >
                      <span class="icon is-small">
                        <font-awesome-icon :icon="['fas', 'plus']" />
                      </span>
                    </a>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="menu-options column">
          <div class="selected-options-wrapper">
            <div class="title is-3">
              {{
                $t(
                  'Components.ToDos.ModalShareToDo.Modal_LabelSelectedAccounts'
                )
              }}
            </div>
            <div v-if="!isLoading" class="wrapper-selectedOptions">
              <message
                v-if="mToDo.Notifications.length === 0 && !isLoading"
                :message="
                  $t(
                    'Components.ToDos.ModalShareToDo.Modal_MessageNothingSelected'
                  )
                "
              />
              <table v-else class="table is-fullwidth is-striped">
                <tbody>
                  <tr
                    v-for="(account, index) in mToDo.Notifications"
                    :key="`sl${index}`"
                  >
                    <td>{{ account.ProfileName }}</td>
                    <td>
                      <a @click="removeAccount(account)">
                        <span class="icon">
                          <font-awesome-icon
                            :icon="['fas', 'trash-alt']"
                            class="has-text-danger"
                          />
                        </span>
                      </a>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </template>
  </ui-component-modal>
</template>

<script>
import Vue from 'vue'
import accountProvider from '@/providers/account'
import todoProvider from '@/providers/todo'
import { mapState, mapMutations, mapGetters } from 'vuex'

const Message = () =>
  import(/* webpackChunkName: "settings-options" */ '@/components/UI/Message')
// const SearchBar = () =>
//   import(/* webpackChunkName: "settings-options" */ '@/components/SearchBar')

export default {
  components: {
    Message,
    //SearchBar,
  },

  props: {
    showModal: {
      type: Boolean,
      default: false,
    },
    onClickCancel: {
      type: Function,
      required: true,
    },
    todo: {
      type: Object,
      default: null,
    },
  },

  data() {
    return {
      accounts: [],
      groups: [],
      mToDo: null,
      infoTab: 'Groups',
      searchTerm: '',
      assignToSelf: false,

      isSaving: false,
      isSavingError: false,
      isSavingSuccess: false,
      isLoading: false,
    }
  },

  computed: {
    ...mapState('accountStore', ['accountGroups']),
    ...mapState('locationStore', ['locationState']),
    ...mapGetters('locationStore', ['accountRights']),
    ...mapGetters({
      profile: 'profileStore/getProfile',
    }),

    displayAccounts() {
      let result = []
      if (this.accounts.length > 0) {
        for (let i = 0; i < this.accounts.length; i++) {
          let accountIndex = this.mToDo.Notifications.findIndex(
            (a) => a.ProfileId === this.accounts[i].ProfileId
          )
          if (accountIndex === -1) {
            result.push(this.accounts[i])
          }
        }
      }
      return result
    },
  },

  created() {
    this.mToDo = JSON.parse(JSON.stringify(this.todo))
    this.getLocationAccounts()

    if (this.mToDo.Notifications.length > 0) {
      for (let i = 0; i < this.mToDo.Notifications.length; i++) {
        if (this.mToDo.Notifications[i].ProfileId === this.profile.Id) {
          this.assignToSelf = true
        }
      }
    }
  },

  methods: {
    ...mapMutations('accountStore', ['setAccountGroups']),

    checkIfAssigned() {
      let addIndex
      let removeIndex

      if (this.assignToSelf && this.mToDo.Notifications.length > 0) {
        addIndex = this.mToDo.Notifications.findIndex(
          (a) => a.ProfileId === this.profile.Id
        )
      }

      if (
        (this.assignToSelf && addIndex === -1) ||
        this.mToDo.Notifications.length === 0
      ) {
        this.mToDo.Notifications.push({
          Body: this.mToDo.Body,
          Id: this.mToDo.Id,
          IsRead: false,
          IsReadOn: 0,
          LinkItemId: this.mToDo.LinkItemId,
          LinkTypeId: this.mToDo.LinkTypeId,
          Location: this.mToDo.LocationId,
          NoteId: this.mToDo.Notifications.NoteId,
          ProfileId: this.profile.Id,
          ProfileName: this.profile.FullName,
          Subject: this.mToDo.Subject,
        })
      }

      if (!this.assignToSelf && this.mToDo.Notifications.length > 0) {
        removeIndex = this.mToDo.Notifications.findIndex(
          (a) => a.ProfileId === this.profile.Id
        )
      }

      if (removeIndex > -1) {
        Vue.delete(this.mToDo.Notifications, removeIndex)
      }
    },

    changeTab(tab) {
      this.infoTab = tab
    },

    async getLocationAccounts() {
      if (this.accounts.length === 0) {
        this.isLoading = true
        let page = 0

        await accountProvider.methods
          .getAllLocationAccounts(this.locationState.Id, this.searchTerm, page)
          .then((response) => {
            if (response.status === 200) {
              this.accounts = response.data.Results
            }
          })
          .finally(() => {
            this.isLoading = false
          })
      }
    },

    addAccount(account) {
      this.mToDo.Notifications.push({
        Body: this.mToDo.Body,
        Id: this.mToDo.Id,
        IsRead: false,
        IsReadOn: 0,
        LinkItemId: this.mToDo.LinkItemId,
        LinkTypeId: this.mToDo.LinkTypeId,
        Location: this.mToDo.LocationId,
        NoteId: this.mToDo.Notifications.NoteId,
        ProfileId: account.ProfileId,
        ProfileName: account.FullName,
        Subject: this.mToDo.Subject,
      })

      if (account.ProfileId === this.profile.Id) {
        this.assignToSelf = true
      }
    },

    addGroup(group) {
      if (group.Accounts.length > 0) {
        for (let i = 0; i < group.Accounts.length; i++) {
          let index = this.mToDo.Notifications.findIndex(
            (a) => a.ProfileId === group.Accounts[i].ProfileId
          )

          if (index === -1) {
            this.mToDo.Notifications.push({
              Body: this.mToDo.Body,
              Id: this.mToDo.Id,
              IsRead: false,
              IsReadOn: this.mToDo.IsReadOn,
              LinkItemId: this.mToDo.LinkItemId,
              LinkTypeId: this.mToDo.LinkTypeId,
              Location: this.mToDo.LocationId,
              NoteId: this.mToDo.Id,
              ProfileId: group.Accounts[i].ProfileId,
              ProfileName: group.Accounts[i].FullName,
              Subject: this.mToDo.Subject,
            })
            if (group.Accounts[i].ProfileId === this.profile.Id) {
              this.assignToSelf = true
            }
          } else {
            null
          }
        }
      }
    },

    removeAccount(account) {
      // Remove selected account from newTodo accountids array
      let index = this.mToDo.Notifications.findIndex(
        (notification) => notification.ProfileId === account.ProfileId
      )
      if (index > -1) {
        Vue.delete(this.mToDo.Notifications, index)
      }
      if (account.ProfileId === this.profile.Id) {
        this.assignToSelf = false
      }
    },

    search(searchTerm) {
      this.searchTerm = searchTerm
      this.accounts = []
      this.getLocationAccounts()
    },

    shareToDo() {
      let self = this
      let profileIds = []

      for (let i = 0; i < self.mToDo.Notifications.length; i++) {
        profileIds.push(self.mToDo.Notifications[i].ProfileId)
      }
      if (!self.isSaving) {
        self.isSaving = true
      }
      todoProvider.methods
        .shareToDo(self.mToDo.Id, self.mToDo.LocationId, profileIds)
        .then((response) => {
          if (response.status === 200) {
            self.$emit('todoShared', self.mToDo)
            self.isSavingSuccess = true

            setTimeout(() => {
              self.onClickCancel()
            }, 1500)
          }
        })
        .catch((error) => {
          self.isSavingError = true
        })
        .finally(() => {
          self.isSaving = false
        })
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/styles/template';
.tabs {
  margin-bottom: 2px;
}
.tabs-info {
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  border-radius: 5px;
  border-top: none;
  height: 240px;
}

.table {
  td {
    &:first-child {
      width: 100%;
    }
  }
}
</style>
